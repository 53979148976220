import { AdvSearchFilter } from '@components/constants';

export enum StandardStatusEnum {
  All = AdvSearchFilter.All,
  Draft = 5001,
  Current = 5002,
  Expiring = 5003,
  Discontinued = 5004,
  Withdrawn = 5005,
  Declined = 5006
}

export enum StandardVersionStatusEnum {
  All = AdvSearchFilter.All,
  Draft = 5101,
  Approved = 5102,
  Superseded = 5103,
  Withdrawn = 5104,
  Declined = 5105,
  Discontinued = 5106
}

export enum StandardGradingSchemeEnum {
  AchievedMeritExcellence = 5801,
  AchievedMerit = 5803,
  CreditMerit = 5805,
  CreditMeritExcellence = 5806
}

export enum StandardAssessmentEnum {
  InternallyAssessed = 5501,
  ExternallyAssessed = 5502
}

export enum StandardGradingTypeEnum {
  DefaultExternallyAssessed = 5703,
  InternallyAssessed = 5704,
  ExternallyAssessedSampleVerification = 5705
}

export enum StandardContextEnum {
  NoContext = 5901,
  OptionalContext = 5902,
  MandatoryContext = 5903,
  OptionalContextMultiplesAllowed = 5904,
  MandatoryContextMultiplesAllowed = 5905
}

export enum StandardProcessEnum {
  New = 5201,
  Review = 5202,
  Revision = 5203,
  Rollover = 5204,
  RolloverAndRevision = 5205,
  Reinstatement = 5206,
  ReviewNew = 5207,
  NotSet = 5028
}
