import { AdvSearchFilter } from '@components/constants';

export enum ConsentTypeEnum {
  All = AdvSearchFilter.All,
  ConsentToAssess = 8201,
  BaseScopeForSchools = 8202,
  LimitedBaseScopeForSchools = 8203
}

export enum ConsentStatusEnum {
  All = AdvSearchFilter.All,
  Draft = 8001,
  Granted = 8002,
  NotGranted = 8003,
  Withdrawn = 8004,
  Removed = 8005
}

export enum ConsentStatusStringEnum {
  All = 'All',
  Draft = 'Draft',
  Granted = 'Granted',
  NotGranted = 'Not Granted',
  Withdrawn = 'Withdrawn',
  Removed = 'Removed'
}

export const getConsentStatusString = (status: ConsentStatusEnum): string => {
  switch (status) {
    case ConsentStatusEnum.All:
      return ConsentStatusStringEnum.All;
    case ConsentStatusEnum.NotGranted:
      return ConsentStatusStringEnum.NotGranted;
    case ConsentStatusEnum.Draft:
      return ConsentStatusStringEnum.Draft;
    case ConsentStatusEnum.Granted:
      return ConsentStatusStringEnum.Granted;
    case ConsentStatusEnum.Withdrawn:
      return ConsentStatusStringEnum.Withdrawn;
    case ConsentStatusEnum.Removed:
      return ConsentStatusStringEnum.Removed;
  }
};
