import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlusComponent } from './plus/plus.component';
import { EditComponent } from './edit/edit.component';
import { DeleteComponent } from './delete/delete.component';
import { CopyComponent } from './copy/copy.component';

@NgModule({
  declarations: [PlusComponent, EditComponent, DeleteComponent, CopyComponent],
  imports: [CommonModule],
  exports: [PlusComponent, EditComponent, DeleteComponent, CopyComponent]
})
export class SvgModule {}
