import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VersionStatusEnum } from '@components/constants';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { MessageModalService } from '@components/message-modal/message-modal.service';
import { GetPagedQualificationsParameters } from '@core/services/models/qualification/get-paged-qualifications-parameters';
import { IQualification } from '@core/services/models/qualification/qualification';
import { QualificationService } from '@core/services/qualification.service';
import { map, Observable } from 'rxjs';
import { ITypeaheadBase } from '../typeahead-base';

@Component({
  selector: 'app-qualification-typeahead',
  templateUrl: './qualification-typeahead.component.html',
  styleUrls: ['./qualification-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualificationTypeaheadComponent),
      multi: true
    }
  ]
})
export class QualificationTypeaheadComponent
  extends ControlValueAccessorConnector
  implements OnInit, ControlValueAccessor, ITypeaheadBase
{
  @Input() qualNo: any;
  @Input() removeConfirmationMessage: string = '';
  @Input() hasConfirmationModal: boolean = false;
  @Output() removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() excludedIds: string[] = [];
  @Input() verStatus: VersionStatusEnum[] = [];
  @Input() searchOnlyByNumber: boolean = false;
  @Input() onlyLatestVersion: boolean = false;
  @Input() latestApprovedOrVer1Draft: boolean = false;
  @Output() searchComplete = new EventEmitter<{ hasResults: boolean; searchTerm: string }>();
  placeholder: string = `Start typing qualification number${this.searchOnlyByNumber ? ' or title keyword' : ''}`;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: QualificationService,
    private modalService: MessageModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    let params = new GetPagedQualificationsParameters();
    params.searchText = term;
    params.verStatus = this.verStatus;
    params.searchOnlyByNumber = this.searchOnlyByNumber;
    params.onlyLatestVersion = this.onlyLatestVersion;
    params.latestApprovedOrVer1Draft = this.latestApprovedOrVer1Draft;

    return this.service.paged(params).pipe(
      map(response => {
        const items = response.items;
        const hasResults = items.length > 0;
        this.searchComplete.emit({ hasResults, searchTerm: term });

        return items.filter(item => this.excludedIds?.indexOf(item.id!) == -1);
      })
    );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: IQualification) => `${(x.qualNo, '|', x.englishTitle)}`;

  showConfirmationModal(): void {
    this.modalService.open(this.removeConfirmationMessage).result.then(confirmed => {
      if (confirmed) this.removeEvent.emit();
    });
  }
}
