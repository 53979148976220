import { Lookup } from '@core/services/models/shared/lookup';

export enum RecordTypeEnum {
  Accreditation = 'accreditation',
  Qualification = 'qualification',
  Classification = 'classification',
  Programme = 'programme',
  Standard = 'standard',
  StandardGroup = 'standard-group',
  MicroCredential = 'micro-credential',
  CMR = 'cmr',
  Consent = 'consent',
  RuleGroup = 'rule-group'
}

export enum RecordTypeDisplayEnum {
  Accreditation = 'Accreditation',
  Qualification = 'Qualification',
  Classification = 'Classification',
  Programme = 'Programme',
  Standard = 'Standard',
  StandardGroup = 'Standard Group',
  MicroCredential = 'Micro-credential',
  CMR = 'CMR',
  Consent = 'Consent',
  RuleGroup = 'Rule Group'
}

export enum RecordTypeNumericEnum {
  Qualification = 1,
  Classification = 2,
  Programme = 3,
  Standard = 4,
  StandardGroup = 5,
  MicroCredential = 6,
  CMR = 7,
  Consent = 8,
  Accreditation = 9,
  RuleGroup = 10
}

export enum RecordStatusEnum {
  Draft = 'Draft',
  Current = 'Current',
  Expiring = 'Expiring',
  Discontinued = 'Discontinued',
  Withdrawn = 'Withdrawn',
  Declined = 'Declined'
}

export enum VersionStatusEnum {
  Draft = 1050,
  Approved = 1051,
  Superseded = 1052,
  Withdrawn = 1053,
  Declined = 1054,
  Discontinued = 1055
}

export class VersionStatusString {
  public static get(status: VersionStatusEnum): string {
    switch (status) {
      case VersionStatusEnum.Draft:
        return 'Draft';
      case VersionStatusEnum.Approved:
        return 'Approved';
      case VersionStatusEnum.Superseded:
        return 'Superseded';
      case VersionStatusEnum.Withdrawn:
        return 'Withdrawn';
      case VersionStatusEnum.Declined:
        return 'Declined';
      case VersionStatusEnum.Discontinued:
        return 'Discontinued';
    }
  }
}

export class RecordTypeNumericToRecordType {
  public static get(recordTypeNumeric: RecordTypeNumericEnum): RecordTypeEnum {
    switch (recordTypeNumeric) {
      case RecordTypeNumericEnum.Qualification:
        return RecordTypeEnum.Qualification;
      case RecordTypeNumericEnum.Classification:
        return RecordTypeEnum.Classification;
      case RecordTypeNumericEnum.Programme:
        return RecordTypeEnum.Programme;
      case RecordTypeNumericEnum.Standard:
        return RecordTypeEnum.Standard;
      case RecordTypeNumericEnum.StandardGroup:
        return RecordTypeEnum.StandardGroup;
      case RecordTypeNumericEnum.MicroCredential:
        return RecordTypeEnum.MicroCredential;
      case RecordTypeNumericEnum.CMR:
        return RecordTypeEnum.CMR;
      case RecordTypeNumericEnum.Consent:
        return RecordTypeEnum.Consent;
      case RecordTypeNumericEnum.Accreditation:
        return RecordTypeEnum.Accreditation;
      case RecordTypeNumericEnum.RuleGroup:
        return RecordTypeEnum.RuleGroup;
    }
  }
}

export class RouteBaseForRecordTypeDisplay {
  public static get(recordTypeDisplay: RecordTypeDisplayEnum): string {
    switch (recordTypeDisplay) {
      case RecordTypeDisplayEnum.Qualification:
        return 'qualification';
      case RecordTypeDisplayEnum.Classification:
        return 'classification';
      case RecordTypeDisplayEnum.Programme:
        return 'programme';
      case RecordTypeDisplayEnum.Standard:
        return 'standard';
      case RecordTypeDisplayEnum.StandardGroup:
        return 'standard-group';
      case RecordTypeDisplayEnum.MicroCredential:
        return 'micro-credential';
      case RecordTypeDisplayEnum.CMR:
        return 'cmr';
      case RecordTypeDisplayEnum.Consent:
        return 'consent';
      case RecordTypeDisplayEnum.Accreditation:
        return 'accreditation';
      case RecordTypeDisplayEnum.RuleGroup:
        return 'rule-group';
    }
  }
}

export class RecordTypeDisplayToRecordTypeNumeric {
  public static get(recordTypeDisplay: RecordTypeDisplayEnum): RecordTypeNumericEnum {
    switch (recordTypeDisplay) {
      case RecordTypeDisplayEnum.Qualification:
        return RecordTypeNumericEnum.Qualification;
      case RecordTypeDisplayEnum.Classification:
        return RecordTypeNumericEnum.Classification;
      case RecordTypeDisplayEnum.Programme:
        return RecordTypeNumericEnum.Programme;
      case RecordTypeDisplayEnum.Standard:
        return RecordTypeNumericEnum.Standard;
      case RecordTypeDisplayEnum.StandardGroup:
        return RecordTypeNumericEnum.StandardGroup;
      case RecordTypeDisplayEnum.MicroCredential:
        return RecordTypeNumericEnum.MicroCredential;
      case RecordTypeDisplayEnum.CMR:
        return RecordTypeNumericEnum.CMR;
      case RecordTypeDisplayEnum.Consent:
        return RecordTypeNumericEnum.Consent;
      case RecordTypeDisplayEnum.Accreditation:
        return RecordTypeNumericEnum.Accreditation;
      case RecordTypeDisplayEnum.RuleGroup:
        return RecordTypeNumericEnum.RuleGroup;
    }
  }
}

export class RecordTypeNumericToRecordTypeDisplay {
  public static get(recordTypeNumeric: RecordTypeNumericEnum): RecordTypeDisplayEnum {
    switch (recordTypeNumeric) {
      case RecordTypeNumericEnum.Qualification:
        return RecordTypeDisplayEnum.Qualification;
      case RecordTypeNumericEnum.Classification:
        return RecordTypeDisplayEnum.Classification;
      case RecordTypeNumericEnum.Programme:
        return RecordTypeDisplayEnum.Programme;
      case RecordTypeNumericEnum.Standard:
        return RecordTypeDisplayEnum.Standard;
      case RecordTypeNumericEnum.StandardGroup:
        return RecordTypeDisplayEnum.StandardGroup;
      case RecordTypeNumericEnum.MicroCredential:
        return RecordTypeDisplayEnum.MicroCredential;
      case RecordTypeNumericEnum.CMR:
        return RecordTypeDisplayEnum.CMR;
      case RecordTypeNumericEnum.Consent:
        return RecordTypeDisplayEnum.Consent;
      case RecordTypeNumericEnum.Accreditation:
        return RecordTypeDisplayEnum.Accreditation;
      case RecordTypeNumericEnum.RuleGroup:
        return RecordTypeDisplayEnum.RuleGroup;
    }
  }
}

export class RecordTypeToRecordTypeNumeric {
  public static get(recordType: RecordTypeEnum): RecordTypeNumericEnum {
    switch (recordType) {
      case RecordTypeEnum.Qualification:
        return RecordTypeNumericEnum.Qualification;
      case RecordTypeEnum.Classification:
        return RecordTypeNumericEnum.Classification;
      case RecordTypeEnum.Programme:
        return RecordTypeNumericEnum.Programme;
      case RecordTypeEnum.Standard:
        return RecordTypeNumericEnum.Standard;
      case RecordTypeEnum.StandardGroup:
        return RecordTypeNumericEnum.StandardGroup;
      case RecordTypeEnum.MicroCredential:
        return RecordTypeNumericEnum.MicroCredential;
      case RecordTypeEnum.CMR:
        return RecordTypeNumericEnum.CMR;
      case RecordTypeEnum.Consent:
        return RecordTypeNumericEnum.Consent;
      case RecordTypeEnum.Accreditation:
        return RecordTypeNumericEnum.Accreditation;
      case RecordTypeEnum.RuleGroup:
        return RecordTypeNumericEnum.RuleGroup;
    }
  }
}

export const numberOfRecentlyViewedRecords: number = 10;
export const typeaheadDebounceTime: number = 300;
export const minimumInputLength: number = 3;

export enum RecordSortEnum {
  DateModifiedDesc = 1000,
  NumberAsc = 1001,
  NumberDesc = 1002,
  TitleAsc = 1003,
  TitleDesc = 1004
}

export class RecordSortDisplay {
  public static get(recordSortEnum: RecordSortEnum): string {
    switch (recordSortEnum) {
      case RecordSortEnum.DateModifiedDesc:
        return 'Date modified (default)';
      case RecordSortEnum.NumberAsc:
        return 'Record number (1, 2, 3, ...)';
      case RecordSortEnum.NumberDesc:
        return 'Record number (10, 9, 8, ...)';
      case RecordSortEnum.TitleAsc:
        return 'Record title (A - Z)';
      case RecordSortEnum.TitleDesc:
        return 'Record title (Z - A)';
    }
  }
}

/**
 * Re-usable enum used for validation in Advanced Search Filtering.
 */
export enum AdvSearchFilter {
  All = -1
}

export const AdvSearchAllLookup: Lookup<number> = {
  text: 'All',
  value: AdvSearchFilter.All
};
