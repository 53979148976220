import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { genericItemReMapper } from '@core/helpers/utils';
import { ConsentStatusEnum, ConsentTypeEnum } from '@views/consent-to-assess/constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { map, Observable } from 'rxjs';
import {
  ConsentToAssess,
  IConsentStatus,
  IConsentToAssess,
  IPostConsentToAssess
} from './models/consent-to-assess/consent-to-assess';
import { ConsentValidation } from './models/consent-to-assess/consent-validation';
import { IStandardReference } from './models/consent-to-assess/standard-reference';
import { IClassificationReference } from './models/programme/nzsced-classification';
import { Lookup } from './models/shared/lookup';
import { IStandard, Standard } from './models/standard/standard';
import { ValidationResult } from './models/bulk-update-validation-result-model';

@Injectable({
  providedIn: 'root'
})
export class ConsentToAssessService {
  static consentTypeOptions: Lookup<number>[] = [
    {
      text: 'All',
      value: ConsentTypeEnum.All
    },
    {
      text: 'Consent to assess',
      value: ConsentTypeEnum.ConsentToAssess
    },
    {
      text: 'Base scope for schools',
      value: ConsentTypeEnum.BaseScopeForSchools
    },
    {
      text: 'Limited base scope for schools',
      value: ConsentTypeEnum.LimitedBaseScopeForSchools
    }
  ];

  getConsentTypeOptions(): Lookup<number>[] {
    return ConsentToAssessService.consentTypeOptions;
  }

  getLevelOptions(): Lookup<number>[] {
    let options: Lookup<number>[] = [];
    for (let i = 1; i <= 10; i++) {
      let text = i == 1 ? '1' : `1 to ${i}`;
      options.push({
        text,
        value: i
      });
    }
    return options;
  }

  updateStatus(ctaNo: string, model: ManageStatus): Observable<IConsentToAssess> {
    return this.http.patch<IConsentToAssess>(apiRoutes.consent.updateStatus(ctaNo), model);
  }

  validateStatus(ctaNo: string, statusId: string): Observable<IConsentStatus> {
    return this.http.get<IConsentStatus>(apiRoutes.consent.validateStatus(ctaNo, statusId));
  }

  get(ctaNo: string): Observable<IConsentToAssess> {
    return this.http.get<IConsentToAssess>(apiRoutes.consent.get(ctaNo)).pipe(
      map(result => {
        if (result.std) {
          result.std = genericItemReMapper<IStandard>(result.std as IStandard, Standard) as IStandardReference;
        }
        return result as IConsentToAssess;
      })
    );
  }

  create(model: IPostConsentToAssess): Observable<ConsentToAssess[]> {
    return this.http.post<ConsentToAssess[]>(apiRoutes.consent.create, model);
  }

  bulkUpdate(csv: File, isTrialRun: boolean) {
    const formData: FormData = new FormData();
    formData.append('file', csv, csv.name);
    return this.http.post<ValidationResult[]>(apiRoutes.consent.bulkUpdate(isTrialRun), formData);
  }

  consentAlreadyExistForStandard(standardNumber: string, moeNumber: string): Observable<ConsentValidation> {
    return this.http.get<ConsentValidation>(apiRoutes.standard.doesConsentExistForStandard(standardNumber, moeNumber));
  }

  consentAlreadyExistForClassification(
    level: number,
    classification: IClassificationReference
  ): Observable<ConsentValidation> {
    return this.http.get<ConsentValidation>(
      apiRoutes.classification.doesConsentExistForClassification(classification.internalId, level),
      {
        params: { ...classification }
      }
    );
  }

  public static statusOptions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: ConsentStatusEnum.Draft
    },
    {
      text: 'Granted',
      value: ConsentStatusEnum.Granted
    },
    {
      text: 'Not Granted',
      value: ConsentStatusEnum.NotGranted
    },
    {
      text: 'Removed',
      value: ConsentStatusEnum.Removed
    },
    {
      text: 'Withdrawn',
      value: ConsentStatusEnum.Withdrawn
    }
  ];
  constructor(private http: HttpClient) {}

  forClassification(classificationInternalId: string): Observable<IConsentToAssess[]> {
    return this.http.get<IConsentToAssess[]>(apiRoutes.consent.forClassification(classificationInternalId));
  }

  forStandard(stdNo: string, stdId: string | null): Observable<IConsentToAssess[]> {
    return this.http.get<IConsentToAssess[]>(apiRoutes.consent.forStandard(stdNo, stdId));
  }
}
